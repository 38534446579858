<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Pro-forma Invoice
          #{{ invoice.jobNumber }}
          <span class="float-right">View</span>
        </h1>
        <p v-if="invoice.vatStatus === 1">
          This pro-forma invoice is issued in compliance with HMRC Domestic Reverse Charging Scheme for the Construction Industry
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link
          custom v-slot="{ navigate }"
          :to="`/proformas/print-view/${invoice.id}`">
          <v-btn
            small
            @click="navigate"
            @keypress.enter="navigate"
            role="link"
            class="relative"
            style="top:-4px">
              Print View
          </v-btn>
        </router-link>
        &nbsp;
        <v-btn
          v-if="changesToSave"
          small
          @click="saveProforma"
          class="relative"
          style="top:-4px">
            Save Changes
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-6">
          <v-text-field v-model="invoice.customer_name"></v-text-field>
          <span v-if="invoice.addressOne">
            <v-text-field v-model="invoice.addressOne" label="Address line one"></v-text-field>
          </span>
          <span v-if="invoice.addressTwo">
            <v-text-field v-model="invoice.addressTwo" label="Address line two"></v-text-field>
          </span>
          <span v-if="invoice.addressThree">
            <v-text-field v-model="invoice.addressThree" label="Address line three"></v-text-field>
          </span>
          <span v-if="invoice.addressFour">
            <v-text-field v-model="invoice.addressFour" label="Address line four"></v-text-field>
          </span>
          <span v-if="invoice.addressFive">
            <v-text-field v-model="invoice.addressFive" label="Address line five"></v-text-field>
          </span>
          <span v-if="invoice.postcode">
            <v-text-field v-model="invoice.postcode" label="Postcode"></v-text-field>
          </span>
          <v-textarea
            v-model="invoice.additional_codes"
            label="Additional information, codes etc"
            placeholder="
              Additional information here, i.e. Processing contact name, ODAS No, Cost Code etc.
            ">
          </v-textarea>
      </v-col>
      <v-col class="col-6">
        <p>
          Document number: {{ invoice.jobNumber }}
        </p>
        <p>
          <v-text-field label="PO Number" v-model="invoice.poNumber"></v-text-field>
        </p>
        <p>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedDatefns"
                clearable
                label="Invoice date / tax point"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="invoice.date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="invoice.date"
              @change="menu = false"
            ></v-date-picker>
          </v-menu>
        </p>
        <p>
          Account reference: {{ invoice.accountReference }}
        </p>
        <p>
          <v-textarea
            v-model="invoice.notes"
            label="Internal notes (will not show on print view)"
            rows="3"></v-textarea>
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Description</th>
                <th class="text-left">Material</th>
                <th class="text-left">Colour</th>
                <th class="text-left">Quantity</th>
                <th class="text-left">Units</th>
                <th class="text-left">Rate</th>
                <th class="text-left">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, index) in invoice.lines" :key="index">
                <td>
                  <v-text-field v-model="line.description"></v-text-field>
                </td>
                <td>
                  <v-autocomplete
                    :items="materials"
                    v-model="line.material_id"></v-autocomplete>
                </td>
                <td>
                  <v-select
                    v-model="line.colour_id"
                    :items="colours"></v-select>
                </td>
                <td>
                  <v-text-field
                    v-model="line.quantity"
                    type="number"
                    min="0"
                    step="0.01"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="line.units"></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="line.pounds"
                    type="number"
                    step="0.01"></v-text-field>
                </td>
                <td>
                  {{ (line.quantity * line.pounds) | currency }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn
          @click="addInvoiceLine"
          x-small
          class="ml-5">Add line</v-btn>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <v-textarea
          v-model="invoice.description_notes"
          label="Add note to show below invoice lines"
          rows="3"></v-textarea>
      </v-col>
    </v-row>
    <v-row class="pt-10">
      <v-col class="col-6">
        <p>
          <v-text-field v-model="invoice.siteLocation" label="Delivery location"></v-text-field>
        </p>
        <p>
          <v-text-field v-model="invoice.workDescription" label="Description"></v-text-field>
        </p>
        <p>
          <v-select
            v-model="invoice.contact_id"
            label="Contact"
            :items="contacts"
            item-text="full_name"
            item-value="id"></v-select>
        </p>
      </v-col>
      <v-col class="col-6 pr-20">
        <p>
          Sub-total:
          <span class="float-right">
            {{ subTotal | priceInPounds | currency }}
          </span>
        </p>
        <p>
          VAT @20%:
          <span v-if="invoice.status !== 1" class="float-right">
            <span v-if="invoice.vatStatus === 2">
              {{ vat | priceInPounds | currency }}
            </span>
          </span>
        </p>
        <p>
          Total:
          <span class="float-right">
            {{ (subTotal + vat) | priceInPounds | currency }}
          </span>
        </p>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { format, parseISO } from 'date-fns';
import axios from '../../axios';
  
export default {
  name: 'InvoicesView',
  data() {
    return {
      changesToSave: false,
      changeNumber: 0,
      invoice: {
        lines: [],
        date: '',
      },
      contacts: [],
      menu: false,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      subTotal: 0,
      vat: 0,
      message: '',
      showMessage: false,
      colours: [],
      materials: [],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    computedDateFormattedDatefns () {
      return this.invoice.date ? format(parseISO(this.invoice.date), 'EEEE, MMMM do yyyy') : ''
    },
    userid() {
      return this.$store.state.userid;
    }
  },
  watch: {
    startDate () {
      this.invoice.date = this.formatDate(this.startDate);
    },
    invoice: {
      deep: true,
      handler() {
        let subtotal = 0;
        for ( let i = 0; this.invoice.lines.length > i; i += 1) {
          const lineTotal = this.invoice.lines[i].quantity * this.invoice.lines[i].pounds;
          subtotal += lineTotal;
        }
        this.subTotal = Math.round(subtotal * 100);
        if (this.changeNumber > 1) {
          this.changesToSave = true;
        }
        this.changeNumber += 1;
      },
    },
  },
  methods: {
    addInvoiceLine() {
      const line = {
        description: '',
        quantity: 0,
        pounds: 0,
        units: '',
        material_id: 0,
        colour_id: 0,
      };
      this.invoice.lines.push(line);
    },
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    saveProforma() {
      const postData = {};
      postData.invoice = this.invoice;
      postData.userid = this.userid;
      axios.post(`/invoices/saveUpdates.json?token=${this.token}`, postData)
        .then((response) => {
          this.invoice = response.data.invoice;
          this.message = response.data.message;
          this.showMessage = true;
        });
    },
    getColours() {
      axios.get(`/colours/getAll.json?token=${this.token}`)
        .then((response) => {
          this.colours = response.data;
          this.getMaterials();
        });
    },
    getMaterials() {
      axios.get(`/materials/getAll.json?token=${this.token}`)
        .then((response) => {
          this.materials = response.data;
        });
    },
    getProforma() {
      axios.get(`/proformas/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.invoice = response.data;
        this.subTotal = response.data.value;
        this.vat = response.data.vat;
        this.contacts = response.data.contacts;
      });
    },
  },
  mounted() {
    this.getColours();
    this.getProforma();
  },
};
</script>